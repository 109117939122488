import numeral from "numeral";
import {Container} from "aurelia-dependency-injection";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";

export class ValueFormatter {

    static format(formatter, value, currency) {

        const currencyConverter = Container.instance.get(CurrencyValueConverter);

        if (value === null || value === undefined) {
            return '';
        }

        switch (formatter) {

            case 'money':
                return currencyConverter.toView({amount: value, currency: currency ?? 'EUR'});

            case 'diff-money':
                return currencyConverter.toView({amount: value, currency: currency ?? 'EUR'});

            case 'number':
                return numeral(value).format('0,0.00');

            case 'diff':
                return numeral(value).format('+0,0.00');

            case 'percentage':
                return numeral(.01 * value).format('0.00%');

            case 'diff-percentage':
                return numeral(.01 * value).format('+0.0%');

            case 'days':
                const days = Math.floor(value);
                const hours = Math.floor(24 * (value - days));
                const minutes = Math.floor(1440 * (value - days - hours / 24));
                const seconds = Math.round(86400 * (value - days - hours / 24 - minutes / 1440));

                return (0 < days ? days + 'd ' : '') + [hours, minutes, seconds].map(x => (10 > x ? '0' : '') + x).join(':');

            case 'time':
                return numeral(value).format('0,0.0');

            default:
                return numeral(value).format('0,0');
        }
    }
}

export default ValueFormatter;
