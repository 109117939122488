import React from "react";

export const AureliaLink = ({href, params, children}) => {

    const url = new URL(href, document.location.href);

    Object.entries(params).forEach(([k, v]) => url.searchParams.set(k, v));

    // @todo use Aurelia router

    return (
        <a href={url.toString()}>
            {children}
        </a>
    );
}

export const StatsLabel = ({label}) => {

    if (!label) {
        return null;
    }

    if (label.displayView) {

        const displayLabel = label.label ?? label.displayLabel ?? label.objectLabel ?? label.id;
        const {displayView, ...params} = label;

        delete params.displayLabel;
        delete params.objectLabel;
        delete params.label;
        delete params.modelId;

        return (
            <AureliaLink href={"/view/" + displayView} params={params}>
                <span dangerouslySetInnerHTML={{__html: Array.isArray(displayLabel) ? displayLabel.join(', ') : displayLabel}}/>
            </AureliaLink>
        );
    }

    let displayLabel = label.label ?? label.displayLabel ?? label.objectLabel ?? label;

    return Array.isArray(displayLabel) ? displayLabel.join(', ') : displayLabel;
}

export default StatsLabel;


