import React from "react";
import {toLabel} from "./utils";

import "c3/c3.css";
import ValueFormatter from "../service/value-formatter";
import {Chart} from "./chart";

const SUM = "Summe";

export const GraphViewer = ({data, grouping, labels, columns, interval, showSum}) => {
    console.log('grapher', {data, grouping, labels, columns, interval, showSum})

    labels = grouping?.length ? labels[grouping[0]] : [];

    let dateFormat = "%d.%m.%Y";

    // needs a default value, otherwise it throws an error in case of null value
    interval ||= '1y';

    if (-1 < interval.indexOf('y')) {
        dateFormat = '%Y';
    } else if (-1 < interval.indexOf('M')) {
        dateFormat = '%m/%Y';
    } else if (-1 < interval.indexOf('w')) {
        dateFormat = '%W';
    }

    const graphData = Object.entries(data)
        .filter(([k]) => "__sums__" !== k || showSum)
        .map(([k, entry]) => [
            "__sums__" === k ? SUM : toLabel(k, labels),
            entry instanceof Array ? entry : entry.__sums__
        ])
        .sort(([a], [b]) =>
            SUM === a ? -1 :
                SUM === b ? 1 :
                    a.localeCompare(b)
        );

    // Determine data that could be included in same graph

    const graphs = new Map;

    columns.forEach(({label, format, aggregation, identifier}, index) => {
        if (!graphs.has(identifier)) {
            graphs.set(identifier, []);
        }
        graphs.get(identifier).push(index);
    });

    const hiddenLabels = [];

    let returnValue = [];

    for (const [graphKey, graphIndexes] of graphs) {

        const columnData = [['x']];

        graphData.forEach((data, index) => {
            if (data[0] === "") {
                data[0] = "Wert";
                hiddenLabels.push("Wert");
            }

            let flatData = data.flat();
            let flatRow = [];

            flatRow.push(flatData[0]?.v ?? flatData[0]);

            flatData.forEach(function (v, flatIndex) {

                if (graphIndexes.indexOf(flatIndex - 1) > -1) {
                    flatRow.push(v?.v ?? v);
                }
            });

            columnData.push(flatRow);
        })

        graphIndexes.forEach(i => columnData[0].push(columns[i].date));

        console.log('COLUMN', columnData, columns);

        returnValue.push(
            <Graph
                key={graphKey}
                chartId={graphKey}
                columns={columnData}
                formatter={columns[graphIndexes[0]].format}
                format={dateFormat}
                hiddenLabels={hiddenLabels}
                convertedCurrency={columns[graphIndexes[0]]?.convertedCurrency ?? 'EUR'}
            />
        );
    }

    return <div>
        {returnValue.map((v) => {
            return v;
        })}
    </div>;
}

export const Graph = ({label, columns, format, formatter, hiddenLabels, convertedCurrency, chartId}) => (
    <Chart
        chartId={chartId}
        config={{
            padding: {
                right: 30
            },
            data: {
                x: 'x',
                xFormat: '%Y-%m-%d',
                columns,
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {format}
                },
                y: {
                    tick: {
                        format: value => ValueFormatter.format(formatter, value, convertedCurrency)
                    }
                }
            },
            title: {
                text: label
            },
            tooltip: {
                tick: {
                    format: value => ValueFormatter.format(formatter, value, convertedCurrency)
                }
            },
            legend: {
                hide: hiddenLabels
            }
        }}/>
);

export default GraphViewer;
