import {bindable, bindingMode, customElement, inject} from "aurelia-framework";
import {createElement} from "react";
import {render, unmountComponentAtNode} from "react-dom";
import {Client} from '../api/client';
import {FormField} from "../form/object/form-field";
import {ConfigurationLoader} from "../form/loader/configuration-loader";
import {FlashService} from "../flash/flash-service";
import {UrlUtils} from "../utilities/url-utils";
import {TimeAggregation} from "./time-aggregation/time-aggregation-viewer";
import {DialogService} from "aurelia-dialog";
import {SubmitHandler} from "../form/submit/submit-handler"
import {AuthTokenStorage} from "../auth/auth-token-storage";
import {AureliaConfiguration} from "aurelia-configuration";

import "./time-track-statistics.less";

const today = new Date();
const ranges = [
    {
        id: 'this-week',
        label: 'diese Woche (Mo bis heute)',
        value: [
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1),
            today
        ]
    },
    {
        id: 'last-week',
        label: 'letzte 7 Tage',
        value: [
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        ]
    },
    {
        id: 'this-month',
        label: 'dieser Monat',
        value: [
            new Date(today.getFullYear(), today.getMonth(), 1),
            today
        ]
    },
    {
        id: 'last-month',
        label: 'letzter Monat',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0)
        ]
    },
    {
        id: 'last-30-days',
        label: 'letzte 30 Tage',
        value: [
            new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() - 31),
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        ]
    },
    {
        id: 'this-year',
        label: 'dieses Jahr',
        value: [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31)
        ]
    },
    {
        id: 'last-year',
        label: 'letztes Jahr',
        value: [
            new Date(today.getFullYear() - 1, 0, 1),
            new Date(today.getFullYear() - 1, 11, 31)
        ]
    },
    {
        id: 'last-2-years',
        label: 'letzte zwei Jahre',
        value: [
            new Date(today.getFullYear() - 2, 0, 1),
            new Date(today.getFullYear() - 1, 11, 31)
        ]
    }
];

const rangesShortcuts = ranges.map(r => [r.id, JSON.stringify(r.value.map(d => moment(d).local().format("YYYY-MM-DD")))]);

@customElement('sio-time-track-statistics')
@inject(Element, Client, ConfigurationLoader, FlashService, UrlUtils, DialogService, SubmitHandler, AuthTokenStorage, AureliaConfiguration)
export class TimeTrackStatistics {
    modelField = {
        modelId: 'statistics/stats-model',
    };

    @bindable({defaultBindingMode: bindingMode.oneTime}) range;
    @bindable({defaultBindingMode: bindingMode.oneTime}) hideFilters = [];

    container;

    filterFields = [];
    filterValue = {};
    rangeShortcut;

    showAction = {
        actionClass: 'btn btn-primary btn-sm',
        icon: 'fa fa-play',
        label: 'statistics.show',
        showLabel: false
    }

    clearAction = {
        actionClass: 'btn btn-default btn-sm',
        icon: 'fa fa-eraser',
        label: 'statistics.clear',
        showLabel: false
    }

    loadAction = {
        actionClass: 'btn btn-default btn-sm',
        icon: 'fa fa-upload',
        label: 'statistics.load',
        showLabel: false
    }

    saveAction = {
        actionClass: 'btn btn-default btn-sm',
        icon: 'fa fa-download',
        label: 'statistics.save',
        showLabel: false,
        formId: 'statistics/stats-report',
        data: {}
    }

    constructor(element, client, configLoader, flash, urlUtils, dialog, apiSubmit, token, aureliaConfig, sioClient) {
        this.element = element;
        this.client = client;
        this.configLoader = configLoader;
        this.flash = flash;
        this.urlUtils = urlUtils;
        this.dialog = dialog;
        this.submit = apiSubmit;
        this.token = token;
        this.aureliaConfig = aureliaConfig;
        this.endpoint = 'customer-instance/time-track-statistics/get-records';
    }

    attached() {
        document.addEventListener('resize', this._resize);
        this._resize();
    }

    /**
     * @fixme we don't need this anymore when whole layout is grid based
     */
    _resize() {
        const width = this.element.offsetWidth;

        if (width < 768) {
            this.element.style = {};
            return;
        }

        this.element.style.height = (document.documentElement.clientHeight - this.element.getBoundingClientRect().top) + "px";

        console.debug('resizing statistics', {height: this.element.style.height});
    }

    bind() {
        this.processFilterValue();

        this.updateReportConfig().then(() =>
            this.filterValue.range && this.filterValue.interval && this.filterValue.report && this.show()
        );
    }

    processFilterValue() {
        if (this.filterValue.range) {

            const range = rangesShortcuts.find(r => r[0] === this.filterValue.range);

            if (range) {
                this.filterValue.range = JSON.parse(range[1]);
            }

        } else if (this.filterValue.from) {

            this.filterValue.range = this.dateMapper([this.filterValue.from, this.filterValue.to]);
            delete this.filterValue.from;
            delete this.filterValue.to;

        } else {
            this.filterValue.range = JSON.parse(rangesShortcuts.find(([id]) => "last-month" === id)[1]);
        }
    }

    async updateReportConfig() {
        const data = await this.configLoader.get('customer-instance/aggregate-report', this.filterValue);

        this.filterFields = [];

        for (let field of data.fields || []) {
            if (this.hideFilters.includes(field.property)) {
                continue;
            }

            if (field.hidden) {
                this.filterValue[field.property] = field.default;
                continue;
            }

            let value = this.filterValue[field.property];

            if (field.property === 'range') {
                field.options = Object.assign({}, field.options, {ranges});
            }

            if (!value && field.default) {
                value = field.default;
                this.filterValue[field.property] = value;
            }

            this.filterFields.push(new FormField(this, field, field.property, value));
        }

        const fields = data.fields.map(({property}) => property);

        for (let field of Object.keys(this.filterValue)) {
            if (!fields.includes(field)) {
                delete this.filterValue[field];
            }
        }

        this.updateParams();
    }

    change(field) {

        switch (field.property) {

            case "model":
                this.filterValue = {
                    range: this.filterValue.range,
                    interval: this.filterValue.interval,
                    dateField: this.filterValue.dateField,
                    organizations: this.filterValue.organizations,
                    model: field.value
                }
                break;

            case "organizations":
                this.filterValue.organizations = field.value.map(({id}) => id);
                break;

            default:
                this.filterValue[field.property] = field.value;
        }

        this.updateParams();
    }

    show() {
        this.results = null;
        const formattedFilterValue = {
            ...this.filterValue,
            range: this.filterValue.range.map(d => moment(d).local().format()),
        };

        return this.client
            .post(this.endpoint, formattedFilterValue)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Response not ok: ${response.message || ''}`);
                }

                this.results = response.data;
                this.render();
            })
            .catch(error => {
                console.error(error);
                const errorMessage = error?.data?.localizedMessage
                    || 'Statistik kann mit diesen Filtern nicht angezeigt werden. Bitte korrigieren Sie Ihre Angaben!';
                this.flash.error(errorMessage);
            });
    }

    clear() {
        this.filterValue = {
            range: this.filterValue.range,
            interval: "1y"
        };
        return this.updateReportConfig();
    }

    updateParams() {
        const params = Object.assign({}, this.filterValue);
        const range = rangesShortcuts.find(e => e[1] === JSON.stringify(params.range));

        if (range) {
            params.range = range[0];
        } else {
            params.from = moment.utc(params.range[0]).local().format('YYYY-MM-DD');
            params.to = moment.utc(params.range[1]).local().format('YYYY-MM-DD');
            delete params.range;
        }

        this.urlUtils.setParams(params, null, true);

        delete params.from;
        delete params.to;
        this.parameters = params;
    }

    render() {
        if (!this.results) {
            return;
        }


        switch (this.results.type) {

            case 'time-aggregation':
            case 'time-aggregation-mongo':

                let counter = 0;
                const calcSums = data => {
                    if (!data || data instanceof Array) {
                        return data ?? [];
                    }
                    const sums = new Map(this.results.columns.map((_, index) => [index, null]));
                    Object.values(data).forEach(entry => {
                        calcSums(entry).forEach((value, index) => {
                            if (null !== value) {

                                let sum = sums.get(index) ?? {v: 0, c: this.results.data.baseCurrency};

                                sum.v += value.v ?? 0;

                                if (value.err) {
                                    sum.err = value.err;
                                }

                                sums.set(index, sum);
                            }
                        });
                    });
                    //setting the sums which need to be re-calculated if the aggregation is avg
                    data.__sums__ = Array.from(sums.values());

                    //TODO AVG

                    //setting counter with number of elements
                    counter = Object.keys(data).length - 1;
                    //iterating through pre-caluculated sum values
                    Object.values(data.__sums__).forEach((value, index) => {
                        if (null !== value) {
                            //check for the aggregation type
                            const aggregation = this.results.columns[index].aggregation;
                            if (aggregation === 'avg') {
                                sums.set(index, ((value) / counter));
                            }
                        }
                    });

                    //resetting the value based on aggregation
                    return data.__sums__ = Array.from(sums.values());
                };

                calcSums(this.results.data);

                render(
                    createElement(
                        TimeAggregation,
                        Object.assign({
                            interval: this.filterValue.interval,
                        }, this.results)
                    ),
                    this.container
                );
                break;

            case 'force-graph':
                this.container && unmountComponentAtNode(this.container);
                break;
        }

        this.disableExcelExport();
    }

    dateMapper = dates => dates.map(
        date => !date || 10 <= date.length ? date :
            new Date(date + "T00:00:00").toPlainISO()
    );

    disableExcelExport() {
        const button = document.querySelector('button[title="Excel-Export"]');
        console.log('button', button)
        if (button) {
            button.parentElement.style.display = 'none';
        }
    }
}
