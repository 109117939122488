import {inject, bindable, customElement} from "aurelia-framework";
import {Client} from '../api/client';
import {DialogService} from 'aurelia-dialog';
import {Confirm} from "../dialog/confirm";
import $ from "jquery";
import * as _ from 'lodash';

@inject(Client, DialogService)
@customElement('sio-settings-editor')
export class SettingsEditor {

    @bindable type;
    @bindable user;
    @bindable organization;

    panes = [];

    constructor(client, dialogService) {
        this.client = client;
        this.dialogService = dialogService;
    }

    bind() {
        this.client.get('settings/panes/' + this.type).then(panes => {

            panes = _.filter(panes, (o) => {

                if ((this.user || this.organization) && (o.instanceOnly || o.viewId)) {
                    return false;
                }

                return true;
            });

            panes = _.sortBy(panes, ['weight']);

            this.panes = _.groupBy(panes, 'category');
        });
        this.selectedPaneData = null;
        this.selectedPane = null;
    }

    selectPane(pane) {

        if (pane.viewId) {
            this.selectedPane = pane;
            return;
        }

        let request = {
            conditions: {
                settingsPaneId: pane.id,
            }
        };

        let userRef = null;
        let orgRef = null;

        if (this.user) {
            userRef = {
                id: this.user.id,
                modelId: this.user.modelId,
            };

            request.conditions.user = {'$eq': userRef};
        } else {
            request.conditions.user = {'$exists': false};
        }

        if (this.organization) {
            orgRef = {
                id: this.organization.id,
                modelId: this.organization.modelId,
            };

            request.conditions.organization = {'$eq': orgRef};
        } else {
            request.conditions.organization = {'$exists': false};
        }

        return this.client.get('settings/settings?' + $.param(request)).then(data => {

            this.selectedPane = pane;

            if (data.items.length > 0) {
                this.selectedPaneData = data.items[0].id;

            } else {
                //todo Default data?
                let selectedPane = {
                    'settingsPaneId': pane.id
                };

                if (this.user) {
                    selectedPane.user = userRef;
                }

                if (this.organization) {
                    selectedPane.organization = orgRef;
                }

                this.selectedPaneData = selectedPane;
            }

            console.debug('data', this.selectedPaneData);
        });
    }

    postSubmit()
    {
        if (_.isString(this.selectedPaneData)) {
            return;
        }

        this.selectPane(this.selectedPane);
    }

    reset()
    {
        if (!this.selectedPaneData || !_.isString(this.selectedPaneData) || (!this.user && !this.organization)) {
            return;
        }

        let message = {
            message: 'settings.reset_to_instance_confirm',
            cancelMessage: 'sio.cancel',
            okMessage: 'settings.reset'
        };

        this.dialogService.open({viewModel: Confirm, model: message}).whenClosed(response => {
            if (response.wasCancelled) {
                return;
            }

            return this.client.remove('settings/settings/' + this.selectedPaneData).then(success => {
                return this.selectPane(this.selectedPane);
            });
        });
    }
}
