import {bindable, inject} from 'aurelia-framework';
import Springy from 'springy';
import * as moment from 'moment';
import {DateFormat} from "../service/date-format";
import {ValueFormatter} from "../service/value-formatter";

import "./force-graph.less";

// layout stuff copied and simplified from springyui

const nodeFont = "bold 48px Roboto, sans-serif";
const edgeFont = "24px Roboto, sans-serif";
const stiffness = 400.0;
const repulsion = 400.0;
const damping = 0.5;
const minEnergyThreshold = 0.00001;
const edgeLabelsUpright = true;
const defaultDisplacement = -21;
const nodeRadius = 40;

// helpers for figuring out where to draw arrows
const intersect_line_line = (p1, p2, p3, p4) => {
    const denom = ((p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y));

    // lines are parallel
    if (denom === 0) {
        return false;
    }

    const ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denom;
    const ub = ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denom;

    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false;
    }

    return new Springy.Vector(p1.x + ua * (p2.x - p1.x), p1.y + ua * (p2.y - p1.y));
};

const intersect_line_box = (p1, p2, p3, w, h) => {
    const topLeft = {x: p3.x, y: p3.y};
    const topRight = {x: p3.x + w, y: p3.y};
    const bottomLeft = {x: p3.x, y: p3.y + h};
    const bottomRight = {x: p3.x + w, y: p3.y + h};

    return intersect_line_line(p1, p2, topLeft, topRight) ||
        intersect_line_line(p1, p2, topRight, bottomRight) ||
        intersect_line_line(p1, p2, bottomRight, bottomLeft) ||
        intersect_line_line(p1, p2, bottomLeft, topLeft) ||
        false;
};

@inject(DateFormat)
export class ForceGraphCustomElement {

    @bindable entries;
    @bindable interval;

    canvas;
    graph;

    dateFormat;
    valueFormat;

    constructor(dateFormat) {
        this.dateFormat = dateFormat;
        this.graph = new Springy.Graph();
    }

    bind() {
        this._buildGraph();
    }

    entriesChanged() {
        this._buildGraph();
    }

    intervalChanged() {
        this._buildGraph();
    }

    attached() {
        document.addEventListener('resize', this._resize);
        this._resize();
        this._buildLayout();
    }

    detached() {
        document.removeEventListener('resize', this._resize);
    }

    _resize() {
        if (!this.canvas) {
            return;
        }
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = window.innerHeight - this.canvas.getBoundingClientRect().top - 32;
        console.debug('resizing canvas', {width: this.canvas.width, height: this.canvas.height});
    }

    _buildLayout() {
        const canvas = this.canvas;
        const ctx = canvas.getContext('2d');
        const graph = this.graph;

        this._resize();

        if (this.layout) {

            this.renderer.stop();
            this.layout.stop();
            this.renderer = null;
            this.layout = null;
        }

        const layout = this.layout = new Springy.Layout.ForceDirected(graph, stiffness, repulsion, damping, minEnergyThreshold);

        // calculate bounding box of graph layout.. with ease-in
        let currentBB = layout.getBoundingBox();
        let targetBB = {
            bottomleft: new Springy.Vector(-3, -3),
            topright: new Springy.Vector(3, 3)
        };

        // auto adjusting bounding box
        Springy.requestAnimationFrame(function adjust() {
            targetBB = layout.getBoundingBox();
            // current gets 20% closer to target every iteration
            currentBB = {
                bottomleft: currentBB.bottomleft.add(targetBB.bottomleft.subtract(currentBB.bottomleft)
                    .divide(10)),
                topright: currentBB.topright.add(targetBB.topright.subtract(currentBB.topright)
                    .divide(10))
            };

            Springy.requestAnimationFrame(adjust);
        });

        // convert to/from screen coordinates
        const toScreen = p => {
            const size = currentBB.topright.subtract(currentBB.bottomleft);
            return new Springy.Vector(
                p.subtract(currentBB.bottomleft).divide(size.x).x * canvas.width,
                p.subtract(currentBB.bottomleft).divide(size.y).y * canvas.height
            );
        };

        const getTextWidth = node => {

            if (!node.hasOwnProperty('data')) {
                node.data = {};
            }

            const text = node.data.label || node.id;

            if (node._width && node._width[text]) {
                return node._width[text];
            }

            ctx.save();
            ctx.font = nodeFont;
            const width = ctx.measureText(text).width;
            ctx.restore();

            node._width || (node._width = {});
            node._width[text] = width;

            return width;
        };

        Springy.Node.prototype.getWidth = () => getTextWidth(this);

        this.renderer = new Springy.Renderer(layout,

            // clear
            () => ctx.clearRect(0, 0, canvas.width, canvas.height),

            // draw edge
            (edge, p1, p2) => {
                const x1 = toScreen(p1).x;
                const y1 = toScreen(p1).y;
                const x2 = toScreen(p2).x;
                const y2 = toScreen(p2).y;
                const direction = new Springy.Vector(x2 - x1, y2 - y1);
                const normal = direction.normal().normalise();
                const from = graph.getEdges(edge.source, edge.target);
                const to = graph.getEdges(edge.target, edge.source);
                const total = from.length + to.length;

                // Figure out edge's position in relation to other edges between the same nodes
                let n = 0;
                for (let i = 0; i < from.length; i++) {
                    if (from[i].id === edge.id) {
                        n = i;
                    }
                }

                // Figure out how far off center the line should be drawn
                const offset = normal.multiply(-(defaultDisplacement * n - defaultDisplacement * (total - 1)));
                const s1 = toScreen(p1).add(offset);
                const s2 = toScreen(p2).add(offset);
                const intersection = intersect_line_box(s1, s2, {
                    x: x2 - nodeRadius,
                    y: y2 - nodeRadius
                }, nodeRadius * 2, nodeRadius * 2) || s2;
                const lineEnd = intersection.subtract(direction.normalise().multiply(6));

                // edge line
                ctx.lineWidth = 2;
                ctx.strokeStyle = '#000000';
                ctx.beginPath();
                ctx.moveTo(s1.x, s1.y);
                ctx.lineTo(lineEnd.x, lineEnd.y);
                ctx.stroke();
                ctx.save();

                // arrow
                ctx.fillStyle = '#000000';
                ctx.translate(intersection.x, intersection.y);
                ctx.rotate(Math.atan2(y2 - y1, x2 - x1));
                ctx.beginPath();
                ctx.moveTo(-16, 6);
                ctx.lineTo(0, 0);
                ctx.lineTo(-16, -6);
                ctx.lineTo(-12.8, 0);
                ctx.closePath();
                ctx.fill();
                ctx.restore();
                ctx.save();

                // label
                ctx.textAlign = "center";
                ctx.textBaseline = "top";
                ctx.font = edgeFont;
                ctx.fillStyle = '#000000';
                let angle = Math.atan2(s2.y - s1.y, s2.x - s1.x);
                let displacement = defaultDisplacement;

                if (edgeLabelsUpright && (angle > Math.PI / 2 || angle < -Math.PI / 2)) {
                    displacement *= -1;
                    angle += Math.PI;
                }

                const textPos = s1.add(s2).divide(2).add(normal.multiply(displacement));
                ctx.translate(textPos.x, textPos.y);
                ctx.rotate(angle);
                ctx.fillText(edge.data.label, 0, -2);
                ctx.restore();
            },

            // draw node
            (node, p) => {
                const s = toScreen(p);

                ctx.save();

                ctx.beginPath();
                ctx.fillStyle = "#FFFFFF";
                ctx.strokeStyle = "#000000";
                ctx.arc(s.x, s.y, nodeRadius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.stroke();

                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.font = nodeFont;
                ctx.fillStyle = "#000000";
                ctx.fillText(node.data.label || node.id, s.x, s.y + 2);

                ctx.restore();
            }
        );

        this.renderer.start();
    }

    _buildGraph() {
        // clear
        this.graph.filterEdges(() => false);
        this.graph.filterNodes(() => false);

        if (!this.entries || !this.entries.length) {
            return;
        }

        const nodes = new Set;
        const edges = new Map;

        this.entries.forEach(entry => {
            if (/^(.+) → (.+)$/.test(entry.transition)) {

                const from = RegExp.$1;
                const to = RegExp.$2;

                nodes.add(from);
                nodes.add(to);

                if (edges.has(entry.transition)) {
                    edges.get(entry.transition).labels.push(this._getLabel(entry));
                } else {
                    edges.set(entry.transition, {from, to, labels: [this._getLabel(entry)]});
                }
            }
        });

        const data = {
            nodes: Array.from(nodes),
            edges: Array.from(edges.values()).map(({from, to, labels}) => ([from, to, {label: labels.join('; ')}]))
        };

        this.graph.loadJSON(data);

        if (this.canvas) {
            this._buildLayout();
        }
    }

    _getLabel = ({date, value}) => (("1y" === (this.interval ?? "1y")) ?
            date.substr(0, 4) :
            moment(date).format(this.dateFormat.getDateFormat(this.interval))
    ) + ': ' + ValueFormatter.format('percentage', value);
}
