import * as _ from "lodash";

export const getSettings = async (settingsPaneId, client) => {
    if (!client) {
        throw new Error('You must provide API client instance as second argument of function');
    }

    let allSettings = await client.get('settings/settings');

    if (!allSettings || _.isEmpty(allSettings)) {
        return null;
    }

    const settings = allSettings.items.find(item => item.settingsPaneId === settingsPaneId);
    return settings ? settings.settings : null;
};