

export class DateFormat
{

    getDateFormat(interval)
    {
        if (interval.indexOf('y') > -1) {
            return 'Y';
        } else if (interval.indexOf('M') > -1) {
            return 'MM/Y';
        } else if (interval.indexOf('w') > -1) {
            return 'W';
        }

        return 'd.M.Y';
    }

}
