import {
    bindable,
    customElement,
    inject,
    bindingMode,
    observable,
} from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { Client } from "../api/client";
import getPropertyByPath from "../utilities/get-property-by-path";
import { I18N } from "aurelia-i18n";

@customElement("sio-settings")
@inject(Client, EventAggregator, I18N)
export class Settings {
    @bindable object;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) searchValue;

    @observable searchValue = "";
    loading = true;

    constructor(client, ea, i18n) {
        this.client = client;
        this.ea = ea;
        this.i18n = i18n;
    }

    async bind() {
        this.loading = true;

        const config = await this.client.get(
            this.object.modelId + "/" + this.object.id + "/settings"
        );
        const settings = this.object.settings || {
            modelId: this.object.modelId + "-settings",
        };

        this.panels = (config.panels || []).sort(
            (a, b) => b.priority - a.priority
        );

        const usedTabs = [];

        this.panels.forEach((panel) => {
            panel.object = getPropertyByPath(
                settings,
                panel.options.settings.property
            ) || { modelId: panel.options.modelId };
            panel.object.parent = this.object;
            panel.actionContext = {
                id: this.object.id,
                modelId: this.object.modelId,
            };
            usedTabs.push(panel.tab);
        });

        this.tabs = (config.tabs || [])
            .filter((tab) => usedTabs.includes(tab.id))
            .sort((a, b) => a.weight - b.weight);

        if (config.tabs.length) {
            const id = sessionStorage.getItem("sio_settings_tab");

            this.selectTab(
                config.tabs.find((tab) => tab.id === id) || config.tabs[0]
            );
        }
        this.defaultTabs = this.tabs;
        this.defaultPanels = this.panels;

        this.loading = false;
    }

    attached() {
        this.subscription = this.ea.subscribe(
            "sio_form_post_submit",
            (event) => {
                if (
                    event.config &&
                    this.object.modelId === event.config.modelId
                ) {
                    this.loading = true;
                    this.object = this.client
                        .get(this.object.modelId + "/" + this.object.id)
                        .then((object) => {
                            this.object = object;
                            return this.bind();
                        });
                }
            }
        );
    }

    detached() {
        if (this.subscription) {
            this.subscription.dispose();
            this.subscription = null;
        }
    }

    selectTab(tab) {
        if (this.searchValue !== "") {
            this.resetDisplayTabs(
                this.filterPanelsByField(this.panels, this.searchValue),
                this.defaultTabs,
                tab
            );
        } else {
            this.activeTab = tab;
            this.leftColumn = this.panels.filter(
                (p) => p.tab === tab.id && "right" !== p.column
            );
            this.rightColumn = this.panels.filter(
                (p) => p.tab === tab.id && "right" === p.column
            );
        }
        sessionStorage.setItem("sio_settings_tab", tab.id);
    }

    filterPanelsByField(panels, keyword) {
        const filteredPanels = [];
        for (const panel of panels) {
            const panelLabel = this.i18n.tr(panel.title).toLowerCase();
            const keywordExistsInPanel = panelLabel.includes(
                keyword.toLowerCase()
            );
            if (keywordExistsInPanel) {
                filteredPanels.push(panel);
            } else {
                const filteredFields = panel.fields.filter((field) =>
                    field?.label?.toLowerCase().includes(keyword.toLowerCase())
                );
                if (filteredFields.length > 0) {
                    const filteredPanel = { ...panel, fields: filteredFields };
                    filteredPanels.push(filteredPanel);
                }
            }
        }
        return filteredPanels;
    }

    resetDisplayTabs(panels, tabs, activeTab = null) {
        this.tabs = tabs.filter((tab) => panels.some((d) => d.tab == tab?.id));
        if (
            !activeTab &&
            !this.tabs.some((tab) => tab?.id === this.activeTab?.id)
        ) {
            this.activeTab = this.tabs[0];
        }
        if (activeTab) {
            this.activeTab = activeTab;
        }
        this.leftColumn = panels.filter(
            (p) => p.tab === this.activeTab.id && "right" !== p.column
        );
        this.rightColumn = panels.filter(
            (p) => p.tab === this.activeTab.id && "right" === p.column
        );
    }

    searchValueChanged(newValue, oldValue) {
        if (newValue !== "") {
            const filteredPanels = this.filterPanelsByField(
                this.panels,
                newValue
            );
            this.resetDisplayTabs(filteredPanels, this.defaultTabs);
        }
        if (!newValue) {
            this.resetDisplayTabs(this.defaultPanels, this.defaultTabs);
        }
    }
}
