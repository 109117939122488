import React, {useMemo, useState} from "react";
import StatsLabel from "./stats-label";
import ValueFormatter from "../service/value-formatter";
import {toLabel} from "./utils";
import dayjs from "dayjs";

import "./table-viewer.less";
import {Tooltip, Whisper} from "rsuite";

const PERIODS = Object.freeze({
    year: {"1y": "Y", "1M": "MMY", "1w": "WWY", "1d": "DDMMY"},
    period: {"1y": "Y", "1M": "YMM", "1w": "YWW", "1d": "YMMDD"}
});

export const TableViewer = ({
                                columns: baseColumns,
                                grouping,
                                data,
                                labels,
                                showSum,
                                interval,
                                diff: [diff, diffMode]
                            }) => {

    console.log('GROUPING', grouping);

    const [sortColumn, setSortColumn] = useState(1);

    const columns = useMemo(
        () => {
            console.debug("calculating columns");
            let columns = baseColumns.map((c, i) => Object.assign({index: i}, c));

            if (!diff) {
                return columns;
            }

            columns = columns
                .map(c => {
                    let label = c.label.split(', ');
                    label.pop();
                    label = label.join(', ');
                    return [label + ' ' + moment(c.date).format(PERIODS[diff][interval]), c];
                })
                .sort(([a], [b]) => a.localeCompare(b))
                .map(c => c[1]);

            const newColumns = [];

            if ("year" === diff) {
                for (let i = 0; i < columns.length; i += 2) {
                    newColumns.push(columns[i]);
                    newColumns.push(columns[i + 1]);
                    if (dayjs(columns[i].date).diff(columns[i + 1].date, 'year') !== 0) {
                        newColumns.push({
                            diff: [columns[i + 1].index, columns[i].index],
                            format: "diff" === diffMode && "money" === columns[i].format ? "diff-money" : diffMode
                        });
                    }
                }
            } else {
                columns.forEach((c, i) => {
                    if (i + 1 < columns.length && columns[i].identifier === columns[i + 1].identifier) {
                        newColumns.push(c);
                        newColumns.push(columns[i + 1]);

                        if (dayjs(columns[i].date).diff(columns[i + 1].date, 'year') !== 0 || dayjs(columns[i].date).diff(columns[i + 1].date, 'month') !== 0) {
                            newColumns.push({
                                diff: [columns[i + 1].index, c.index],
                                format: "diff" === diffMode && "money" === c.format ? "diff-money" : diffMode
                            });
                        }
                    } else {
                        newColumns.push(c);
                    }
                });
            }
            return newColumns;
        },
        [diff, diffMode, baseColumns]
    );

    const sums = (!showSum || !data.__sums__?.length) ? null : (
        <tr>
            <td/>
            <TableCells columns={columns} data={data.__sums__}/>
        </tr>
    );

    return (
        <div className="table-responsive statistics-table">
            <table className="table table-bordered table-striped">
                <thead>
                <tr className="heading">
                    <td className="text-right">
                        <TableSort index={1} sortColumn={sortColumn} setSortColumn={setSortColumn}/>
                    </td>
                    {columns.map(({label, diff}, index) => (
                        <td key={label + index || diff} className="text-right">
                            {label}
                            <TableSort index={2 + index} sortColumn={sortColumn} setSortColumn={setSortColumn}/>
                        </td>
                    ))}
                </tr>

                {sums}
                </thead>

                <tbody>
                {sortOrder(data, grouping?.length ? labels[grouping[0]] : [], sortColumn, columns).map(group => (
                    <TableGroup
                        key={group}
                        group={group}
                        columns={columns}
                        grouping={grouping}
                        data={data[group]}
                        labels={labels}
                        level={0}
                        sortColumn={sortColumn}
                        diff={diff}
                    />
                ))}
                </tbody>

                {sums && <tfoot>{sums}</tfoot>}
            </table>
        </div>
    );
}

const TableGroup = ({group, columns, grouping, data, labels, level, sortColumn}) => {

    const label = grouping?.length ? <StatsLabel label={labels[grouping[level]][group] ?? group}/> : "Summe";
    const indent = {paddingLeft: (10 * level + 8) + 'px'};

    if (data instanceof Array) {
        return (
            <tr>
                <td style={indent}>{label}</td>
                <TableCells data={data} columns={columns}/>
            </tr>
        );
    }

    const [open, setOpen] = useState(false);

    return (
        <>
            <tr className="table-group-header" onClick={() => setOpen(!open)}>
                <td style={indent}>
                    {level < grouping?.length && (
                        <i className={"fa fa-folder-" + (open ? "open" : "closed")}
                           style={{marginRight: "5px"}}/>
                    )}
                    {label}
                </td>

                <TableCells data={data.__sums__ || []} columns={columns}/>
            </tr>

            {open && sortOrder(data, labels[grouping[level + 1]], sortColumn, columns).map(subGroup => (
                <TableGroup
                    key={subGroup}
                    group={subGroup}
                    columns={columns}
                    grouping={grouping}
                    data={data[subGroup]}
                    labels={labels}
                    level={level + 1}
                    sortColumn={sortColumn}
                />
            ))}
        </>
    );
};

const TableCells = ({data, columns}) => {
    data = rowData(data, columns);

    return columns.map((column, index) => (
        <TableCell key={index} column={column} value={data[index] ?? null}/>
    ));
}

const TableCell = ({column: {format, convertedCurrency}, value}) => {

    return <td className="text-right">

        {value?.org != null ?
            <Whisper trigger="hover" placement={'left'} speaker={<Tooltip>

                <div>
                    {
                        value.org.map(original => (
                            <p>
                                {ValueFormatter.format(format, original?.v, original?.c)}<br/>
                                <small>{ValueFormatter.format(format, original?.converted, value?.c ?? convertedCurrency)}</small>
                            </p>
                        ))
                    }
                </div>

            </Tooltip>}>
                <span>{null !== value?.v && ValueFormatter.format(format, value?.v, value?.c ?? convertedCurrency)}</span>
            </Whisper> :
            <>
                {null !== value?.v && ValueFormatter.format(format, value?.v, value?.c ?? convertedCurrency)}
            </>
        }

        {value?.err ?
            <Whisper trigger="hover" placement={'left'} speaker={<Tooltip>{value?.err}</Tooltip>}>
                <span> <i className={"fa fa-circle-exclamation text-danger"}></i></span>
            </Whisper> : null}
    </td>;
};

const TableSort = ({index, sortColumn, setSortColumn}) => {

    let className = "fa fa-sort";

    if (index === sortColumn) {
        className = "fa fa-sort-up";
    } else if (index === -1 * sortColumn) {
        className = "fa fa-sort-down";
    }

    return (
        <span
            style={{paddingLeft: "4px"}}
            className={className}
            onClick={() => setSortColumn((index === sortColumn ? -1 : 1) * index)}
        />
    );
};

const rowData = (data = [], columns) => columns.map(({index, diff, format}) => {

    if (index || 0 === index) {
        return data[index] ?? null;
    }

    const a = data[diff[0]]?.v ?? null, b = data[diff[1]]?.v ?? null;

    if (!a && !b) {
        return null;
    }

    switch (format) {
        case "diff-percentage":
            return {v: !a || !b ? null : 100 * ((a - b) / b)};

        case "diff":
        case "diff-money":
            return {v: (a ?? 0) - (b ?? 0), c: a.c};

        default:
            throw new Error("unknown diff format " + diff);
    }
});

const sortOrder = (data, labels, sortColumn, columns) => {

    const sortData = Object.entries(data)
        .filter(([k]) => "__sums__" !== k)
        .map(([k, v]) => [k, v instanceof Array ? v : v.__sums__]);

    if (1 === sortColumn || -1 === sortColumn) {
        return sortData
            .map(([k]) => [k, toLabel(k, labels)])
            .sort((a, b) => sortColumn * a[1].localeCompare(b[1]))
            .map(([k]) => k);
    }

    const direction = Math.sign(sortColumn);
    const column = Math.abs(sortColumn) - 2;

    return sortData
        .map(([k, values]) => [k, rowData(values, columns)])
        .map(([k, values]) => [k, values[column]])
        .sort((a, b) => direction * (a[1].v - b[1].v))
        .map(([k]) => k);
}

export default TableViewer;
